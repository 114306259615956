import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { isNullOrUndefined } from "./predicates";
export var typed = function typed(cb) {
  return function (args) {
    return cb(args);
  };
};
export var getOrElse = function getOrElse(a, b) {
  return pipe(a, O.fromNullable, O.getOrElse(function () {
    return b;
  }));
};
export var matchIsEmpty = function matchIsEmpty(onEmpty, onSome) {
  return function (arr) {
    return arr.length === 0 ? onEmpty() : onSome(arr);
  };
};
export var foldNullablesTuple = function foldNullablesTuple(onEmpty, onSome) {
  return function (arr) {
    return arr.some(isNullOrUndefined) ? onEmpty() : onSome(arr);
  };
};
export var foldNullables = function foldNullables(rec, onEmpty, onSome) {
  return isNullOrUndefined(rec) || Object.values(rec).some(isNullOrUndefined) ? onEmpty() : onSome(rec);
};