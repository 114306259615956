export var join = function join(separator) {
  return function (arr) {
    return arr.join(separator);
  };
};
export var prop = function prop(p) {
  return function (obj) {
    return obj[p];
  };
};
export var ret = function ret(arg) {
  return function () {
    return arg;
  };
};