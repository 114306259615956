export var not = function not(p) {
  return function (arg) {
    return !p(arg);
  };
};
export var isNull = function isNull(arg) {
  return arg === null;
};
export var isNullOrUndefined = function isNullOrUndefined(arg) {
  return arg === null || arg === undefined;
};